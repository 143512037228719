<template>
  <div class="user-app-detail-page w-100">
    <b-form @submit.prevent="handleValidate">
      <basic-subheader>
        <template v-slot:actions>
          <b-button
            class="btn btn-light ml-3"
            type="button"
            @click="returnPage"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Arrows/arrow-left.svg" />
            </span>
            Huỷ
          </b-button>
          <b-button
            class="btn btn-light ml-3"
            type="button"
            @click.stop="handleReset"
          >
            <span class="svg-icon">
              <inline-svg src="/media/svg/icons/Neolex/Basic/refresh-cw.svg" />
            </span>
            Reset dữ liệu
          </b-button>
          <template v-if="form_type === 'create'">
            <b-button class="btn btn-primary ml-3" type="submit">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/plus.svg" />
              </span>
              Tạo mới
            </b-button>
          </template>
          <template v-else>
            <b-button
              class="btn btn-light ml-3"
              type="button"
              @click.stop="handleResetPass"
            >
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Security/key.svg" />
              </span>
              Reset mật khẩu
            </b-button>
            <b-button class="btn btn-success ml-3" type="submit">
              <span class="svg-icon">
                <inline-svg src="/media/svg/icons/Neolex/Basic/save.svg" />
              </span>
              Cập nhật
            </b-button>
          </template>
        </template>
      </basic-subheader>
      <b-overlay :show="loading">
        <b-container fluid class="user-app-list-page__body mb-6 mt-6">
          <b-row>
            <b-col>
              <div class="card card-custom gutter-b">
                <div class="card-body mt-0">
                  <h5 class="card-title text-success">Thông tin cá nhân</h5>
                  <b-row>
                    <b-col cols="4">
                      <div
                        class="d-flex flex-column align-items-center justify-content-center"
                      >
                        <div
                          style="
                            width: 150px;
                            height: 150px;
                            background-color: #f3f6f9;
                            box-shadow: 0px 4px 4px 0px #000000 10%;
                            position: relative;
                            margin: auto;
                          "
                        >
                          <v-file-input
                            hide-input
                            v-model="file"
                            accept="image/png, image/jpeg, image/bmp"
                            class="icon-edit"
                            @change="onUpload"
                            prepend-icon="mdi-pencil-outline"
                            truncate-length="15"
                          >
                          </v-file-input>
                          <v-img :src="preview" :aspect-ratio="1 / 1"></v-img>
                          <!-- <v-icon
                            @click="clear"
                            v-if="preview"
                            style="
                              position: absolute;
                              bottom: -10px;
                              right: -10px;
                            "
                          >
                            mdi-cancel</v-icon
                          > -->
                        </div>

                        <h5 class="mt-4">{{ fullname }}</h5>
                        <div>
                          <span>Mã số: {{ form.code }}</span>
                          <span
                            >,
                            {{
                              isServicePackOpts[form.servicePack]
                                ? isServicePackOpts[form.servicePack].name
                                : isServicePackOpts[0].name
                            }}</span
                          >
                        </div>
                        <div class="d-flex align-items-center mt-2">
                          <span
                            class="btn btn-active"
                            style="width: 85px; cursor: default"
                            v-if="form.status === 1"
                          >
                            Active
                          </span>
                          <span
                            class="btn btn-inactive"
                            style="width: 85px; cursor: default"
                            v-if="form.status === 0"
                          >
                            Inactive
                          </span>
                          <span
                            class="btn btn-user-app ml-3"
                            style="width: 85px; cursor: default"
                          >
                            App User
                          </span>
                        </div>
                      </div>
                    </b-col>
                    <b-col>
                      <b-row>
                        <b-col>
                          <basic-input
                            label="Họ và Tên"
                            placeholder="Nhập họ và tên"
                            name="fullName"
                            data-vv-as="Họ và tên"
                            :required="true"
                            v-validate="'required'"
                            :value.sync="form.fullName"
                            :state="validateState('fullName')"
                            :invalidFeedback="errors.first('fullName')"
                          ></basic-input>
                        </b-col>
                        <b-col>
                          <b-form-group
                            id="birthday-group"
                            label=""
                            :invalid-feedback="errors.first('birthday')"
                            :state="validateState('birthday')"
                            class="required"
                          >
                            <label for="birthday"
                              >Ngày sinh<span class="aterisk"> *</span></label
                            >
                            <date-picker
                              label="Ngày sinh"
                              placeholder="Nhập ngày sinh"
                              name="birthday"
                              input-class="form-control"
                              type="date"
                              format="DD/MM/YYYY"
                              value-type="timestamp"
                              :disabled-date="notBeforeToday"
                              v-validate="'required'"
                              data-vv-as="Ngày sinh"
                              :state="validateState('birthday')"
                              :invalidFeedback="errors.first('birthday')"
                              v-model="form.dateOfBirth"
                            >
                            </date-picker>
                          </b-form-group>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-select
                            label="Giới tính"
                            placeholder="--- Chọn giới tính ---"
                            name="gender"
                            :required="true"
                            data-vv-as="giới tính"
                            v-validate="'required'"
                            :options="isGenderOpts"
                            :value.sync="form.gender"
                            :state="validateState('gender')"
                            :invalidFeedback="errors.first('gender')"
                            changeValueByObject
                            valueLabel="name"
                          />
                        </b-col>
                        <b-col>
                          <basic-select
                            label="Quốc gia"
                            placeholder="--- Chọn quốc gia ---"
                            name="nation"
                            :apiPath="'/Division/Nations'"
                            :searchField="'searchTerm'"
                            :value.sync="form.nation"
                            :changeValueByObject="true"
                            valueLabel="name"
                            :searchable="false"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-select
                            label="Tỉnh thành"
                            placeholder="--- Chọn tỉnh thành ---"
                            name="province"
                            :apiPath="'/Division/Provinces'"
                            :searchField="'searchTerm'"
                            :searchable="false"
                            :searchParams="{
                              nationId: nationId,
                            }"
                            :value.sync="form.province"
                            valueLabel="name"
                            :changeValueByObject="true"
                          />
                        </b-col>
                        <b-col>
                          <basic-select
                            label="Quận / huyện"
                            placeholder="--- Chọn quận / huyện ---"
                            name="district"
                            :apiPath="'/Division/Dictricts'"
                            :searchField="'searchTerm'"
                            :searchParams="{
                              provinceId: provinceId,
                            }"
                            :value.sync="form.district"
                            valueLabel="name"
                            :changeValueByObject="true"
                            :searchable="false"
                          />
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col>
                          <basic-select
                            label="Phường xã"
                            placeholder="--- Chọn phường xã ---"
                            name="wards"
                            :apiPath="'/Division/Wards'"
                            :searchField="'searchTerm'"
                            :searchParams="{ districtId: districtId }"
                            :value.sync="form.ward"
                            valueLabel="name"
                            :changeValueByObject="true"
                            :searchable="false"
                          />
                          <!-- valueLabel="name" -->
                        </b-col>
                        <b-col>
                          <basic-input
                            label="Địa chỉ"
                            placeholder="Nhập địa chỉ"
                            name="address"
                            :value.sync="form.address"
                          ></basic-input>
                        </b-col>
                      </b-row>
                    </b-col>
                  </b-row>
                  <hr style="margin: 2rem -2.25rem" />
                  <h5 class="card-title text-success">Tài khoản</h5>
                  <b-row>
                    <b-col>
                      <basic-input
                        label="Số điện thoại"
                        placeholder="Nhập số điện thoại"
                        name="phoneNumber"
                        data-vv-as="số điện thoại"
                        :required="true"
                        :value.sync="form.phoneNumber"
                        v-validate="'required|max:10'"
                        input-number
                        maxlength="10"
                        :state="validateState('phoneNumber')"
                        :invalidFeedback="errors.first('phoneNumber')"
                        code="+84"
                        :disabled="form_type === 'edit'"
                      ></basic-input>
                    </b-col>
                    <b-col>
                      <basic-input
                        label="Số điện thoại 2"
                        placeholder="Nhập số điện thoại 2"
                        name="secondPhoneNumber"
                        data-vv-as="số điện thoại"
                        :value.sync="form.secondPhoneNumber"
                        v-validate="'max:10'"
                        :state="validateState('secondPhoneNumber')"
                        :invalidFeedback="errors.first('secondPhoneNumber')"
                        input-number
                        maxlength="10"
                        code="+84"
                      ></basic-input>
                    </b-col>
                    <b-col>
                      <basic-input
                        label="Email"
                        placeholder="Nhập email"
                        name="email"
                        data-vv-as="Email"
                        v-validate="'email'"
                        inputType="email"
                        :value.sync="form.email"
                        :state="validateState('email')"
                        :invalidFeedback="errors.first('email')"
                      ></basic-input>
                    </b-col>
                    <b-col>
                      <basic-select
                        label="Gói dịch vụ"
                        placeholder="--- Chọn gói dịch vụ ---"
                        name="servicePack"
                        :options="isServicePackOpts"
                        :solid="false"
                        disabled
                        :allowEmpty="true"
                        :value.sync="form.servicePack"
                      />
                    </b-col>
                  </b-row>
                  <div class="social-account-connected">
                    <div class="text-dark font-weight-bold">
                      Social Account Connected
                    </div>
                    <div class="d-flex align-items-center mt-2">
                      <div class="d-flex align-items-center mr-8" key="0">
                        <b-avatar
                          badge
                          badge-variant="light"
                          badge-offset="-2px"
                          size="40px"
                        >
                          <template #badge>
                            <inline-svg src="/media/svg/logos/facebook.svg" />
                          </template>
                        </b-avatar>
                        <!-- <div class="ml-4">Nguyễn Gia Bảo</div> -->
                      </div>
                      <div class="d-flex align-items-center mr-8" key="1">
                        <b-avatar
                          badge
                          badge-variant="light"
                          badge-offset="-2px"
                          size="40px"
                        >
                          <template #badge>
                            <inline-svg src="/media/svg/logos/google.svg" />
                          </template>
                        </b-avatar>
                        <!-- <div class="ml-4">Nguyễn Gia Bảo</div> -->
                      </div>
                    </div>
                  </div>
                  <hr style="margin: 2rem -2.25rem" />
                  <h5 class="card-title text-success">Tình Trạng Bệnh</h5>
                  <b-row>
                    <b-col>
                      <basic-select
                        label="Tình trạng bệnh tiểu đường"
                        placeholder="--- Chọn tình trạng bệnh ---"
                        name="diabetesCondition"
                        :options="isDiabetesConditionOpts"
                        changeValueByObject
                        :value.sync="detectionStatus"
                        valueLabel="name"
                      />
                    </b-col>
                    <b-col>
                      <b-form-group
                        id="scheduleDate"
                        label="Thời gian phát hiện bệnh"
                        class="required"
                      >
                        <date-picker
                          label="Thời gian phát hiện bệnh"
                          placeholder="Nhập thời gian phát hiện"
                          input-class="form-control"
                          name="detectionTime"
                          type="year"
                          :disabled-date="notBeforeToday"
                          value-type="timestamp"
                          v-model="detectionTime"
                        >
                        </date-picker>
                      </b-form-group>
                    </b-col>
                    <b-col>
                      <basic-input
                        label="Chiều cao (cm)"
                        placeholder="Nhập chiều cao"
                        name="height"
                        :value.sync="form.height"
                      ></basic-input>
                    </b-col>
                  </b-row>
                  <b-row>
                    <b-col cols="4">
                      <basic-select
                        label="Bệnh viện"
                        placeholder="--- Chọn bệnh viện ---"
                        name="hospital"
                        :options="[]"
                        :solid="false"
                        disabled
                        :allowEmpty="true"
                        :value.sync="form.hospital"
                      />
                    </b-col>
                  </b-row>
                </div>
                <div
                  class="card-footer d-flex align-items-lg-center justify-content-center"
                >
                  <b-button
                    class="btn btn-light ml-3"
                    type="button"
                    @click.stop="handleReset"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/refresh-cw.svg"
                      />
                    </span>
                    Reset dữ liệu
                  </b-button>
                  <b-button
                    class="btn btn-success ml-3"
                    @click.stop="handleValidate"
                  >
                    <span class="svg-icon">
                      <inline-svg
                        src="/media/svg/icons/Neolex/Basic/save.svg"
                      />
                    </span>
                    Cập nhật
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </b-container>
      </b-overlay>
    </b-form>
  </div>
</template>

<script>
import DatePicker from 'vue2-datepicker';
import moment from 'moment-timezone';
export default {
  name: 'UserAppUpdate',
  components: {
    'date-picker': DatePicker,
  },
  props: {
    id: {
      type: String,
      default: null,
    },
    form_type: {
      type: String,
      default: 'edit',
    },
  },
  data() {
    return {
      loading: false,
      fullname: null,
      isGenderOpts: [
        { id: 1, name: 'Nam', engName: 'Male' },
        { id: 2, name: 'Nữ', engName: 'Female' },
      ],
      nation: {},
      province: {},
      district: {},
      ward: {},
      isServicePackOpts: [
        { id: 0, name: 'Thành viên Cơ Bản' },
        { id: 1, name: 'Thành viên Đồng' },
        { id: 2, name: 'Thành viên Bạc' },
        { id: 3, name: 'Thành viên Vàng' },
      ],
      isDiabetesConditionOpts: [],
      form: {},
      file: null,
      preview: null,
      detectionTime: null,
      detectionStatus: null,
      momentFormat: {
        // Date to String
        stringify: (date1) => {
          return date1 ? moment(date1).format('YYYY') : '';
        },
        // String to Date
        parse: (value1) => {
          return value1 ? moment(value1).format('X') : null;
        },
      },
    };
  },
  computed: {
    nationId() {
      return this.form.nation?.id;
    },
    provinceId() {
      return this.form.province?.id;
    },
    districtId() {
      return this.form.district?.id;
    },
  },
  methods: {
    handleReset() {
      this.form.fullName = null;
      this.form.dateOfBirth = null;
      this.form.gender = null;
      this.form.province = null;
      this.form.district = null;
      this.form.ward = null;
      this.form.nation = null;
      this.form.address = null;
      this.file = null;
      this.preview = null;
      this.form.height = null;
      this.detectionStatus = null;
      this.form.phoneNumber = null;
      this.form.secondPhoneNumber = null;
      this.form.email = null;
      this.detectionTime = null;
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
    notBeforeToday(date) {
      return date > new Date();
    },
    clear() {
      this.file = null;
      this.preview = null;
    },
    onUpload() {
      this.preview = URL.createObjectURL(this.file);
    },
    validateState(ref) {
      if (
        this.veeFields[ref] &&
        (this.veeFields[ref].dirty || this.veeFields[ref].validated)
      ) {
        return !this.errors.has(ref);
      }
      return null;
    },

    async returnPage() {
      let confirm = await this.$swal({
        text: 'Các thay đổi sẽ không được lưu trữ, bạn có chắc muốn huỷ?',
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Huỷ',
            value: true,
            visible: true,
            className: 'btn-warning',
            closeModal: true,
          },
        },
      });
      if (confirm) {
        this.$router.push({
          name: 'user_app_list',
        });
      }
    },

    edit() {
      this.$swal({
        title: '',
        text: 'Bạn có chắc muốn cập nhật thông tin này hay không?',
        icon: '/media/svg/icons/SweetAlert/upload-cloud.svg',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: 'btn btn-secondary rounded',
            closeModal: true,
          },
          confirm: {
            text: 'Cập nhật',
            value: true,
            visible: true,
            className: 'btn btn-success rounded',
            closeModal: true,
          },
        },
      }).then(
        function (result) {
          if (result) {
            new Promise()
              .then(() => {
                this.$swal({
                  title: '',
                  text: 'Chúc mừng, bạn đã cập nhật thông tin người dùng thành công!',
                  icon: '/media/svg/icons/SweetAlert/check-circle.svg',
                  buttons: {
                    cancel: {
                      text: 'Quay lại',
                      value: false,
                      visible: false,
                      className: 'btn btn-secondary rounded',
                      closeModal: true,
                    },
                    confirm: {
                      text: 'OK',
                      value: true,
                      visible: true,
                      className: 'btn btn-success rounded',
                      closeModal: true,
                    },
                  },
                });
              })
              .catch(() => {
                this.$swal({
                  title: '',
                  text: 'Lỗi, bạn đã cập nhật thông tin người dùng không thành công!',
                  icon: '/media/svg/icons/SweetAlert/alert-triangle-red.svg',
                  buttons: {
                    cancel: {
                      text: 'Quay lại',
                      value: false,
                      visible: false,
                      className: 'btn btn-secondary rounded',
                      closeModal: true,
                    },
                    confirm: {
                      text: 'OK',
                      value: true,
                      visible: true,
                      className: 'btn btn-success rounded',
                      closeModal: true,
                    },
                  },
                });
              });
          }
        }.bind(this),
      );
    },
    handleValidate() {
      this.$validator.validateAll().then(async (result) => {
        if (!result) {
          setTimeout(() => {
            window.scrollTo(0, 0);
          }, 200);
          return;
        } else {
          let confirm = await this.$swal({
            text: `Bạn có chắc muốn cập nhật thông tin này hay không?`,
            icon: 'warning',
            buttons: {
              cancel: {
                text: 'Quay lại',
                value: false,
                visible: true,
                className: '',
                closeModal: true,
              },
              confirm: {
                text: 'Cập nhật',
                value: true,
                visible: true,
                className: 'btn-success',
                closeModal: true,
              },
            },
          });
          if (!confirm) return;
          this.loading = true;

          let formData = new FormData();

          formData.append('fullName', this.form.fullName);
          formData.append('patientId', this.form.id);

          this.form.dateOfBirth &&
            formData.append(
              'dateOfBirth',
              Math.floor(this.form.dateOfBirth / 1000),
            );
          this.form.gender?.id &&
            formData.append('gender', this.form.gender?.id);
          this.form.nation?.id &&
            formData.append('nationId', this.form.nation?.id);
          this.form.province?.id &&
            formData.append('provinceId', this.form.province?.id);
          this.form.district?.id &&
            formData.append('districtId', this.form.district?.id);
          this.form.ward?.id && formData.append('wardId', this.form.ward?.id);
          this.form.address && formData.append('address', this.form.address);
          this.file && formData.append('image', this.file);
          this.form.height && formData.append('height', this.form.height);
          this.detectionStatus &&
            formData.append(
              'diabetesStatus.status',
              this.detectionStatus.value,
            );
          this.form.phoneNumber &&
            formData.append('phoneNumber', this.form.phoneNumber);
          this.form.secondPhoneNumber &&
            formData.append('secondPhoneNumber', this.form.secondPhoneNumber);
          this.form.email && formData.append('email', this.form.email);
          this.detectionTime &&
            formData.append('diabetesStatus.date', this.detectionTime / 1000);
          this.$api
            .put(`Patient/Input`, formData, {
              headers: {
                'Content-Type': 'multipart/form-data',
              },
            })
            .then(async () => {
              let confirm = await this.$swal({
                text: `Chúc mừng, bạn đã cập nhật thông tin người dùng thành công!`,
                icon: 'success',
                buttons: {
                  confirm: {
                    text: 'OK',
                    value: true,
                    visible: true,
                    className: 'btn-success',
                    closeModal: true,
                  },
                },
              });
              if (!confirm) return;
              this.$router.go(-1);
            })
            .catch((error) => {
              this.$toastr.e({
                title: 'Lỗi!',
                msg: error,
              });
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    async loadDiabeteStates() {
      this.$store.commit('context/setLoading', true);
      try {
        const { data } = await this.$api.get('Patient/DiabeteStates');
        for (const item in data) {
          this.isDiabetesConditionOpts.push({
            value: data[item].key,
            name: data[item].value,
          });
        }
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: error,
        });
      } finally {
        this.$store.commit('context/setLoading', false);
      }
    },
    async loadUserData() {
      this.loading = true;
      try {
        const { data } = await this.$api.get(
          `Patient/${this.$route.params.id}`,
        );
        let gender = this.isGenderOpts.find((e) => e.id == data?.genderType);
        let dateOfBirth = data.dateOfBirth * 1000;
        data.phoneNumber =
          data.phoneNumber && data.phoneNumber.replace('+84', 0);
        data.secondPhoneNumber =
          data.secondPhoneNumber && data.secondPhoneNumber.replace('+84', 0);
        this.form = {
          ...data,
          gender,
          dateOfBirth,
        };
        this.fullname = data.fullname;
        this.detectionTime = data.diabetes?.date * 1000;
        this.detectionStatus = this.isDiabetesConditionOpts.find(
          (e) => e.value == data.diabetes?.status,
        );
        this.preview = data.imageUrl?.url;
        this.nation = JSON.parse(JSON.stringify(data.nation));
        this.province = JSON.parse(JSON.stringify(data.province));
        this.district = JSON.parse(JSON.stringify(data.district));
        this.ward = JSON.parse(JSON.stringify(data.ward));
      } catch (error) {
        this.$toastr.e({
          title: 'Lỗi',
          msg: error,
        });
      } finally {
        this.loading = false;
      }
    },

    async handleResetPass() {
      let id = this.form.accountId;
      let confirm = await this.$swal({
        text: `Mật khẩu mới sẽ được gởi qua email của tài khoản! Bạn có chắc muốn reset mật khẩu cho ${this.form.fullName} không?`,
        icon: 'warning',
        buttons: {
          cancel: {
            text: 'Quay lại',
            value: false,
            visible: true,
            className: '',
            closeModal: true,
          },
          confirm: {
            text: 'Reset',
            value: true,
            visible: true,
            className: 'btn-success',
            closeModal: true,
          },
        },
      });
      if (!confirm) return;
      try {
        this.loading = true;

        await this.$api.post(`Admin/Account/${id}/reset-password`);
        this.$swal({
          text: `Reset mật khẩu thành công! Mật khẩu mới đã được gởi qua tin nhắn SMS và email của tài khoản.`,
          icon: 'success',
          confirm: {
            text: 'OK',
            value: true,
            visible: true,
            className: 'btn-success',
            closeModal: true,
          },
        });
      } catch (e) {
        this.$toastr.e({
          title: 'Lỗi!',
          msg: e,
        });
      } finally {
        this.loading = false;
      }
    },
  },
  created() {
    this.loadDiabeteStates();
    this.loadUserData();
  },
};
</script>
<style lang="scss" scoped>
.pictureInput {
  position: relative;
}
.icon-edit {
  z-index: 100;
  position: absolute;
  top: -15px;
  right: -6px;
  padding: 2px 6px;
  box-shadow: -1px 2px 10px rgba(0, 0, 0, 0.2);
  border-radius: 50%;
  display: flex;
  align-items: center;
  width: fit-content;
  background-color: #fff;
}
.aterisk {
  color: #f64e60;
  font-size: 0.9rem;
}
</style>
