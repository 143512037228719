import { render, staticRenderFns } from "./userAppUpdate.vue?vue&type=template&id=2c8e2015&scoped=true"
import script from "./userAppUpdate.vue?vue&type=script&lang=js"
export * from "./userAppUpdate.vue?vue&type=script&lang=js"
import style0 from "./userAppUpdate.vue?vue&type=style&index=0&id=2c8e2015&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2c8e2015",
  null
  
)

export default component.exports